import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { PlanListWidgetRole } from '../../constants/elements';
import thumbnails from '../../layout-thumbnails';
import { openElementsPanel } from '../../utils/open-elements-panel';
import { getChildComponentRefByIndex, getRootWidget } from '../../utils/widget';
import { openPlanElementsPanel } from '../Plan/editor.controller';
import { openDisclaimerLayoutPanel } from './panels/DisclaimerLayout/open-disclaimer-layout-panel';
import { openPlanListLayoutPanel } from './panels/Layout/open-plan-list-layout-panel';
import { openPlanLayoutPanel } from './panels/PlanLayout/open-plan-layout-panel';
import { openPlanSettingsPanel } from './panels/PlanSettings/open-plan-settings-panel';
import { openPlanListSettingsPanel } from './panels/Settings/open-plan-list-settings-panel';
import { openSubtitleLayoutPanel } from './panels/SubtitleLayout/open-subtitle-layout-panel';
import { openTitleLayoutPanel } from './panels/TitleLayout/open-title-layout-panel';

const UNSELECTABLE_ELEMENTS = [
  PlanListWidgetRole.PlansInfoState,
  PlanListWidgetRole.PlanList,
  PlanListWidgetRole.PlanListStates,
  PlanListWidgetRole.Header,
  PlanListWidgetRole.TitleContainer,
  PlanListWidgetRole.DisclaimerContainer,
  PlanListWidgetRole.PlanVariantDefaultWidget,
  PlanListWidgetRole.PlanVariantHighlightedWidget,
  PlanListWidgetRole.PlanVariantCustomWidget,
  PlanListWidgetRole.PlanListItem,
  PlanListWidgetRole.PlanVariantBox,
  PlanListWidgetRole.PlanListItemContainer,
];

const WidgetVariants = [
  PlanListWidgetRole.PlanVariantDefaultState,
  PlanListWidgetRole.PlanVariantHighlightedState,
  PlanListWidgetRole.PlanVariantCustomState,
] as const;

const widgetRoleToState: Record<(typeof WidgetVariants)[number], string> = {
  [PlanListWidgetRole.PlanVariantDefaultState]: 'default',
  [PlanListWidgetRole.PlanVariantHighlightedState]: 'highlighted',
  [PlanListWidgetRole.PlanVariantCustomState]: 'custom',
};

export const getWidgetManifest: GetWidgetManifestFn = (builder, editorSDK, flowAPI) => {
  builder.set({ displayName: 'Plan List' });

  builder
    .gfpp()
    .set('mainAction1', {
      onClick: (e) => openPlanListSettingsPanel({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
      label: flowAPI.translations.t('blocks.plan-list-settings.title'),
    })
    .set('add', {
      onClick: (e) => openPlanListElementsPanel({ editorSDK, flowAPI, widgetRef: e.detail.componentRef }),
    })
    .set('layout', {
      onClick: (e) => openPlanListLayoutPanel({ editorSDK, componentRef: e.detail.componentRef }),
    });

  builder
    .gfpp('mobile')
    .set('layout', {
      onClick: (e) => openPlanListLayoutPanel({ editorSDK, componentRef: e.detail.componentRef }),
    })
    .set('design', { behavior: 'DEFAULT' });

  builder.behavior().set({ resizable: true, duplicatable: true });

  UNSELECTABLE_ELEMENTS.forEach((element) =>
    builder.configureConnectedComponents(element, (elementBuilder) => {
      elementBuilder.behavior().set({ closed: { hideFromHierarchy: true, selectable: false } });
    }),
  );

  builder.configureWidgetPresets((presetsBuilder) => {
    presetsBuilder.setCategories([
      { name: 'Vertical', id: 'vertical' },
      { name: 'Horizontal', id: 'horizontal' },
    ]);
    presetsBuilder.setPresets([
      { name: 'default', id: 'variants-lhrjp3681', src: thumbnails.vertical, categoryId: 'vertical' },
      { name: 'default_fb', id: 'variants-ltoj5phf', src: thumbnails.vertical_fb, categoryId: 'vertical' },
      { name: 'horizontal', id: 'variants-lm0a2lwk', src: thumbnails.horizontal, categoryId: 'horizontal' },
      { name: 'horizontal_fb', id: 'variants-ltpfdt8c', src: thumbnails.horizontal_fb, categoryId: 'horizontal' },
    ]);
  });

  builder.configureConnectedComponents(PlanListWidgetRole.Title, (disclaimerBuilder) => {
    disclaimerBuilder
      .gfpp()
      .set('layout', {
        onClick: async (e) =>
          openTitleLayoutPanel({ editorSDK, componentRef: await getRootWidget(editorSDK, e.detail.componentRef) }),
      })
      .set('connect', { behavior: 'HIDE' });
  });

  builder.configureConnectedComponents(PlanListWidgetRole.SubtitleContainer, (headerBuilder) => {
    headerBuilder.behavior().set({ closed: { selectable: false, hideFromHierarchy: true } });
  });

  builder.configureConnectedComponents(PlanListWidgetRole.Subtitle, (disclaimerBuilder) => {
    disclaimerBuilder
      .gfpp()
      .set('layout', {
        onClick: async (e) =>
          openSubtitleLayoutPanel({ editorSDK, componentRef: await getRootWidget(editorSDK, e.detail.componentRef) }),
      })
      .set('connect', { behavior: 'HIDE' });
  });

  builder.configureConnectedComponents(PlanListWidgetRole.Disclaimer, (disclaimerBuilder) => {
    disclaimerBuilder
      .gfpp()
      .set('layout', {
        onClick: async (e) =>
          openDisclaimerLayoutPanel({ editorSDK, componentRef: await getRootWidget(editorSDK, e.detail.componentRef) }),
      })
      .set('connect', { behavior: 'HIDE' });
  });
  const t = flowAPI.translations.t;

  WidgetVariants.forEach((widget) => {
    builder.configureConnectedComponents(widget, (widgetVariantBuilder) => {
      widgetVariantBuilder.set({
        displayName: t(`blocks.plan-list-settings-panel.${widgetRoleToState[widget]}-style`),
      });
      widgetVariantBuilder.behavior().set({ closed: { selectable: true, hideFromHierarchy: false } });
      widgetVariantBuilder
        .gfpp()
        .set('mainAction1', {
          label: flowAPI.translations.t('blocks.plan-list.plan.settings.title'),
          onClick: async (e) => {
            const componentRef = await getChildComponentRefByIndex({
              componentRef: e.detail.componentRef,
              editorSDK,
              index: 0,
            });

            openPlanSettingsPanel({
              editorSDK,
              componentRef,
              flowAPI,
            });
          },
        })
        .set('design', { behavior: 'DEFAULT' })
        .set('layout', {
          onClick: async (e) => {
            const componentRef = await getChildComponentRefByIndex({
              componentRef: e.detail.componentRef,
              editorSDK,
              index: 1,
            });
            openPlanLayoutPanel({
              editorSDK,
              componentRef,
            });
          },
        })
        .set('add', {
          onClick: async (e) => {
            const componentRef = await getChildComponentRefByIndex({
              componentRef: e.detail.componentRef,
              editorSDK,
              index: 1,
            });
            openPlanElementsPanel({
              editorSDK,
              widgetRef: componentRef,
              flowAPI,
            });
          },
        });
      widgetVariantBuilder.gfpp('mobile').set('layout', {
        onClick: async (e) => {
          const componentRef = await getChildComponentRefByIndex({
            componentRef: e.detail.componentRef,
            editorSDK,
            index: 1,
          });
          openPlanLayoutPanel({
            editorSDK,
            componentRef: await getChildComponentRefByIndex({
              componentRef,
              editorSDK,
              index: 1,
            }),
          });
        },
      });
    });
  });
};

function openPlanListElementsPanel(params: {
  editorSDK: EditorSDK;
  widgetRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, widgetRef, flowAPI } = params;
  const t = flowAPI.translations.t;
  return openElementsPanel({
    editorSDK,
    widgetRef,
    data: {
      categories: [
        {
          id: 'plan-info',
          title: t('blocks.plan-list-elements-panel.plan-info'),
        },
      ],
      elements: [
        {
          label: t('blocks.plan-list-elements-panel.title'),
          identifier: { role: PlanListWidgetRole.Title },
          categoryId: 'plan-info',
          index: 0,
        },
        {
          label: t('blocks.plan-list-elements-panel.subtitle'),
          identifier: { role: PlanListWidgetRole.Subtitle },
          categoryId: 'plan-info',
          index: 1,
        },
        {
          label: t('blocks.plan-list-elements-panel.plan-list'),
          identifier: { role: PlanListWidgetRole.PlanList },
          categoryId: 'plan-info',
          index: 2,
        },
        {
          label: t('blocks.plan-list-elements-panel.disclaimer'),
          identifier: { role: PlanListWidgetRole.Disclaimer },
          categoryId: 'plan-info',
          index: 3,
        },
      ],
    },
  });
}
